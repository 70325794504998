import * as React from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button, CardActionArea, CardActions, Grid } from '@mui/material';

import { Link } from "react-router-dom";
import content from "./content.json";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";

import CompatCapture from "../../images/COMPAT.png";
import TcatCapture from "../../images/TCAT.png";
import Top100Capture from "../../images/Top 100 Vis.png";
import RoadPerfDashboardCapture from "../../images/tx_road_perf_dashboard.PNG";
import MitigationPlanSystemCapture from '../../images/Mitigation Plans.png'

export default function OverviewCards() {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const navigate = useNavigate();

    return (
        <Grid container spacing={1}>
            <Grid item xs container direction="row" spacing={1}>
                <Grid item xs container direction="column" spacing={1}>
                    <Grid item xs={4}>
                        {/* <Item>xs=8</Item> */}
                        <Card sx={{ width: "100%", height: "100%" }}>
                            <CardActionArea
                                href="https://mobility.tamu.edu/texas-most-congested-roadways/"
                                target="_blank"
                            >
                                <CardMedia
                                    component="img"
                                    height="225"
                                    image={Top100Capture}
                                    alt="Top 100 Most Congested Road Segments"
                                    sx={{ objectFit: "contain" }}
                                />
                            </CardActionArea>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Top 100 Most Congested Road Segments
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {content.overview_text1}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button
                                    size="medium"
                                    color="primary"
                                    href="https://mobility.tamu.edu/texas-most-congested-roadways/"
                                    target="_blank"
                                >
                                    Open
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        {/* <Item>xs=8</Item> */}
                        <Card sx={{ width: "100%", height: "100%" }}>
                            <CardActionArea
                                href="/roadperfdash"
                            // target="_blank"
                            >
                                <CardMedia
                                    component="img"
                                    height="225"
                                    image={RoadPerfDashboardCapture}
                                    alt="COMPAT"
                                    sx={{ objectFit: "contain" }}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Texas Roadway Performance Dashboard
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {content.overview_text4}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Button
                                    size="medium"
                                    color="primary"
                                    href="/roadperfdash"
                                >
                                    Open
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>

                <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs={4}>
                        <Card sx={{ width: "100%", height: "100%" }}>
                            <CardActionArea
                                href="/compat"
                            // target="_blank"
                            >
                                <CardMedia
                                    component="img"
                                    height="225"
                                    image={CompatCapture}
                                    alt="COMPAT"
                                    sx={{ objectFit: "contain" }}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Congestion Management Process Assessment Tool (COMPAT)
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {content.overview_text2}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Button
                                    size="medium"
                                    color="primary"
                                    href="/compat"
                                >
                                    Open
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
                <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs={4}>
                        <Card sx={{ width: "100%", height: "100%" }}>
                            <CardActionArea
                                href="/tcat"
                            // target="_blank"
                            >
                                <CardMedia
                                    component="img"
                                    height="225"
                                    image={TcatCapture}
                                    alt="TCAT"
                                    sx={{ objectFit: "contain" }}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Truck Congestion Analysis Tool (TCAT)
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {content.overview_text3}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Button
                                    size="medium"
                                    color="primary"
                                    href="/tcat"
                                //   target="_blank"
                                >
                                    Open
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card sx={{ width: "100%", height: "100%" }}>
                            <CardActionArea
                                href="/mitigation-plan"
                            // target="_blank"
                            >
                                <CardMedia
                                    component="img"
                                    height="225"
                                    image={MitigationPlanSystemCapture}
                                    alt="COMPAT"
                                    sx={{ objectFit: "contain" }}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Mitigation Plan System 2.0
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {content.overview_text5}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Button
                                    size="medium"
                                    color="primary"
                                    href="/compat"
                                >
                                    Open
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
